<script>
import ClienteMixin from "components/clientes/components/cliente/include/ClienteMixin";

export default {
  name: 'ClienteInstancia',
  mixins: [ClienteMixin],
}
</script>

<template>
  <div class="scliente-page-pad">
    Em breve...
  </div>
</template>
